.grid-container {
  composes: flex from global;
  box-sizing: border-box;
  justify-content: center;
  margin: auto;
  padding-top: 128px;
}

.grid-container-img {
  composes: flex from global;
  box-sizing: border-box;
  justify-content: center;
  margin: auto;
}

.carousel-main-container {
  composes: flex font-regular pl16 pr16 from global;
  flex-direction: column;
  justify-content: center;
  width: 80%;
  max-width: 1150px;
}

.carousel-main-container-img {
  composes: flex font-regular pl16 pr16 from global;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1150px;
  margin-bottom: -96px;
}

.title {
  composes: flex from global;
  flex-direction: column;
  max-width: 525px;
}

.carousel-container {
  composes: flex mt64 from global;
}

.carousel-container-left {
  composes: flex from global;
  width: 43%;
  flex-direction: column;
  margin-bottom: 120px;
}
.carousel-container-left-img {
  composes: flex from global;
  width: 43%;
  flex-direction: column;
  margin-bottom: 96px;
}

.small-title {
  composes: text-18 mb12 text-light from global;
  display: block;
  letter-spacing: 0;
  text-transform: unset;
  width: 100%;
}

.large-title {
  composes: block from global;
  font-size: 40px;
  width: 100%;
}

.content-header {
  composes: text-18 mb4 font-medium from global;
  color: var(--text-color-dark);
}

.content-para {
  composes: text-16 lh-169 from global;
  color: var(--text-color-normal);
}

.carousel-container-right {
  composes: flex relative from global;
  width: 57%;
  overflow: hidden;
  margin-top: -100px;
}

.carousel-container-right-img {
  composes: flex relative from global;
  width: 57%;
  overflow: hidden;
  margin-top: -100px;
}

.carousel-container-right-inner {
  composes: flex from global;
  transition: all 0.6s ease-in-out;
}

@media (--screen-landscape) {
  .carousel-container-right {
    width: 420px;
    margin-top: 20px;
  }
  .carousel-container-right-img {
    width: 420px;
    margin-top: 20px;
  }
}

@media (--screen-tablet) {
  .carousel-container-right {
    width: 620px;
    margin-top: 20px;
  }

  .carousel-container-right-img {
    width: 620px;
    margin-top: 20px;
  }
}

@media (--screen-tablet), (--screen-landscape) {
  .carousel-main-container {
    align-items: center;
  }

  .large-title {
    text-align: center;
  }

  .small-title {
    composes: text-18 from global;
    text-align: center;
  }

  .carousel-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .carousel-container-left {
    display: none;
  }
  .carousel-container-left-img {
    display: none;
  }

  .content-header {
    font-size: 24px !important;
  }

  .content-para {
    font-size: 18px;
    line-height: 1.38;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .grid-container {
    padding: 32px 0;
  }

  .no-top-padding {
    padding-top: 0 !important;
  }
  .title {
    composes: flex from global;
    width: 100%;
    height: 20%;
    max-width: 525px;
    justify-content: center;
    align-items: center;
    padding: 32px 0 32px 0;
    text-align: center;
  }

  .carousel-main-container {
    width: 100%;
  }

  .carousel-main-container-img {
    margin-bottom: -64px;
  }

  .small-title {
    composes: text-16 from global;
    text-align: center;
  }

  .large-title {
    text-align: center;
  }

  .carousel-container {
    flex-direction: column;
    align-items: center;
    margin-top: 0;
  }

  .carousel-container-left {
    display: none !important;
  }
  .carousel-container-left-img {
    display: none !important;
  }

  .carousel-container-right {
    width: 300px !important;
    margin-top: 20px;
  }

  .carousel-container-right-img {
    width: 300px !important;
    margin-top: -96px;
  }

  .content-header {
    line-height: 1.1 !important;
  }

  .content-para {
    font-size: 14px;
    line-height: 1.38;
  }

  .grid-container-img {
    padding-bottom: 48px;
  }
}
