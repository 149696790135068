.carousel-card {
  composes: flex relative pointer pt24 pr16 pb24 pl16 reset-button-style from global;
  width: 100%;
  height: 70px;
  box-shadow: 0 1px 0 0 rgba(129, 135, 140, 0.2);
  transition: 0.2s all linear;
  overflow: hidden;
  text-align: left;
}

.carousel-card:hover {
  background-color: var(--color-white);
}

.carousel-card:hover .carousel-card-content h4 {
  color: var(--color-blue);
}

.carousel-card-active {
  composes: flex relative pointer pt24 pr16 pb24 pl16 reset-button-style from global;
  width: 100%;
  background-color: var(--color-white);
  border-radius: 4px;
  max-height: 150px;
  transition: 0.2s all linear;
  overflow: hidden;
  border: 1px solid;
  text-align: left;
}

.carousel-card p {
  display: none;
}

.carousel-card-icon {
  composes: flex from global;
  align-items: flex-start;
  width: 10%;
}

.carousel-card-content {
  width: 90%;
}

.carousel-card-progress {
  composes: absolute from global;
  width: 100%;
  height: 2px;
  left: 0;
  bottom: 0;
  border-radius: 0 0 4px 4px;
  background-color: var(--background-grey);
}

.carousel-card-progress-content {
  width: 0;
  height: 100%;
  transition: all linear;
}

@media (--screen-tablet), (--screen-landscape) {
  .carousel-card-icon {
    composes: flex from global;
    align-items: center;
    padding-right: 10px;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .carousel-card-icon {
    composes: flex from global;
    margin-top: 24px;
    align-items: center;
    width: 15%;
  }

  .carousel-card-content {
    width: 85%;
  }
}
