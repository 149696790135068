.video-frame {
  composes: br-4 mb64 from global;
  height: 412px;
  width: 732px;
  box-shadow: 0 32px 46px 2px rgba(0, 0, 0, 0.12);
  @media (--screen-tabletOrLower) {
    width: calc(100vw - 32px);
    height: calc((100vw - 32px) / 1.778);
  }
}
