.carousel-item {
  composes: flex from global;
  min-width: 620px;
  height: 100%;
  justify-content: center;
  cursor: grab;
}

.carousel-item:active {
  cursor: grabbing;
}

.carousel-card-icon {
  composes: flex from global;
  align-items: flex-start;
  width: 10%;
}

.img-margin {
  margin-top: -156px;
}

.carousel-card-content {
  width: 90%;
}

.video-slide {
  composes: relative from global;
}

.demo-video {
  composes: absolute from global;
  top: 71px;
  height: 487px;
  transform: translateX(-50%);
  object-fit: contain;
  border-radius: 10px 10px 0 0;
  user-select: none;
}

.video-slide img {
  position: absolute;
  width: 395px;
  height: 580px;
  transform: translateX(-50%);
  vertical-align: middle;
  user-select: none;
}

.video-caption {
  composes: desktop-hidden from global;
}

@media (--screen-landscape) {
  .carousel-item {
    width: 420px;
    min-width: 420px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-bottom: 40px;
  }
}

@media (--screen-tablet) {
  .carousel-item {
    width: 620px;
    min-width: 620px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    padding-bottom: 40px;
  }
}

@media (--screen-tablet), (--screen-landscape) {
  .video-slide {
    composes: flex relative from global;
    height: 400px;
  }

  .video-caption {
    composes: flex from global;
    justify-content: space-between;
    margin-top: 200px;
  }

  /* fixed width and height for image */
  .video-slide img {
    width: 395px;
    height: 558px;
  }

  .img-margin {
    margin-top: 0;
  }

  /* fixed width and height for video */
  .demo-video {
    top: 67px;
    height: 491px;
  }

  .acc-row {
    composes: flex from global;
    max-width: 500px;
  }

  .carousel-card-icon {
    composes: flex from global;
    align-items: center;
    padding-right: 10px;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .carousel-item {
    min-width: 300px !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .video-slide {
    composes: flex from global;
    height: 396px;
  }

  .video-caption {
    composes: flex from global;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    padding: 20px 0;
    height: calc(100% - 410px);
  }

  .video-slide img {
    width: 280px;
    height: 396px;
  }

  .demo-video {
    composes: absolute from global;
    top: 50px;
    height: 346px;
    transform: translateX(-50%);
  }

  .acc-row {
    composes: flex from global;
    width: 90%;
  }

  .carousel-card-icon {
    composes: flex from global;
    margin-top: 4px;
    width: 15%;
  }

  .carousel-card-content {
    width: 85%;
  }
}
