.content-header {
  composes: text-18 mb4 font-medium from global;
  color: var(--text-color-dark);
}

.content-para {
  composes: text-16 lh-169 from global;
  color: var(--text-color-normal);
}

@media (--screen-tablet), (--screen-landscape) {
  .content-header {
    font-size: 24px !important;
  }

  .content-para {
    font-size: 18px;
    line-height: 1.38;
  }
}

@media (--screen-mobile), (--screen-smaller) {
  .content-header {
    line-height: 1.1 !important;
  }

  .content-para {
    font-size: 14px;
    line-height: 1.38;
  }
}
